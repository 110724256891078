import React, { useEffect, useState } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

/**
 * Formats a Date object to a value that input[type="date"] accepts.
 */
const formatDate = (date) => `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;

const DateRangeControl = (props) => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [fromMax, setFromMax] = useState('');

  useEffect(() => {
    setFromMax(to);
  }, [to]);

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      onEnter={() => {
        setTo(formatDate(new Date()));
      }}
      overlay={(
        <Popover
          style={{
            maxWidth: 500,
          }}
        >
          <Popover.Content>
            <ButtonGroup size="sm" className="mb-2">
              <Button
                variant="secondary"
                onClick={() => {
                  setFrom(formatDate(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)));
                  setTo(formatDate(new Date()));
                }}
              >
                Yesterday
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setFrom(formatDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)));
                  setTo(formatDate(new Date()));
                }}
              >
                Last 7 Days
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setFrom(formatDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)));
                  setTo(formatDate(new Date()));
                }}
              >
                Last 30 Days
              </Button>
            </ButtonGroup>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>From</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="date"
                value={from}
                max={fromMax}
                onChange={(evt) => setFrom(evt.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>To</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="date"
                value={to}
                onChange={(evt) => setTo(evt.target.value)}
              />
            </InputGroup>
            <div className="clearfix mt-2">
              <ButtonGroup size="sm" className="float-right">
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setFrom('');
                    setTo('');
                  }}
                >
                  Clear
                </Button>
              </ButtonGroup>
            </div>
          </Popover.Content>
        </Popover>
      )}
    >
      <div>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Button {...props}>{to && from ? `${from} ~ ${to}` : 'Choose Date Range'}</Button>
        <FormControl name="start_date" type="hidden" value={from} />
        <FormControl name="end_date" type="hidden" value={to} />
      </div>
    </OverlayTrigger>
  );
};

export default DateRangeControl;
