import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { PencilFill } from 'react-bootstrap-icons';
import DriverModal from '../DriverModal';
import moment from 'moment';
import apiRoot from '../constants/apiRoot';

const { ExportCSVButton } = CSVExport;

const filterHeaderFormatter = (
  column,
  colIndex,
  { sortElement, filterElement },
) => (
  <div>
    <div>
      {column.text}
      {sortElement}
    </div>
    <div>{filterElement}</div>
  </div>
);

let abortController = null;
let isSearching = false;

const dateFormatter = (date) => {
  return date ?
    moment(date).format('YYYY-MM-DD') :
    '';
};

const truncateFormatter = (value) => {
  return (
    <div style={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }}>
      {value}
    </div>
  );
};

const Drivers = () => {
  const [drivers, setDrivers] = useState([]);
  const [driverFormValues, setDriverFormValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const searchInput = useRef('');

  const search = useDebounceCallback(() => {
    abortController = new AbortController();
    isSearching = true;
    setErrorMessage(false);

    fetch(`${apiRoot}/search/drivers?term=&active=false`, {
      signal: abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => {
        isSearching = false;
        setDrivers(data.message);
      })
      .catch((err) => {
        // rethrow everything but AbortError
        if (err.name !== 'AbortError') {
          setErrorMessage(true);
          isSearching = false;
          throw err;
        }
      });
  }, 300);

  const handleSearch = useCallback(() => {
    if (isSearching) {
      abortController.abort();
    }
    search(searchInput.current.value);
  }, [search]);

  const onTableChange = (type, newState) => {
    console.log(type, newState);
  };

  const handleEditClick = (id) => {
    fetch(`${apiRoot}/driver/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'ok') {
          setDriverFormValues(data.message);
          setShowModal(true);
        }
      });
  };

  const columns = [
    {
      dataField: 'edit',
      text: '',
      isDummyField: true,
      csvExport: false,
      headerStyle: { width: '50px' },
      formatter: (cell, row) => (
        <Button
          variant="primary"
          size="sm"
          block
          onClick={() => {
            handleEditClick(row.id);
          }}
        >
          <div className="d-flex justify-content-center">
            <PencilFill />
          </div>
        </Button>
      ),
    },
    {
      dataField: 'id',
      csvExport: false,
      hidden: true,
    },
    {
      dataField: 'active',
      text: 'Active',
      headerFormatter: filterHeaderFormatter,
      formatter: (value) => (value ? '✓' : ''),
      sort: true,
      headerStyle: {
        width: '80px'
      }
    },
    {
      dataField: 'nick_name',
      text: 'Nick Name',
      headerFormatter: filterHeaderFormatter,
      filter: textFilter(),
      sort: true,
      formatter: (cell, row) => (
        <div>
          <div className="trailer">{cell}</div>
          {row.hazmat &&
            <img height={22} src="/haz.png" alt="" />
          }
          {row.samsara_id &&
            <img height={22} src="/samsara.svg" alt="" />
          }
        </div>
      ),
    },
    {
      dataField: 'first_name',
      text: 'First Name',
      headerFormatter: filterHeaderFormatter,
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'last_name',
      text: 'Last Name',
      headerFormatter: filterHeaderFormatter,
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'can_move_pups',
      text: 'Can Move Pups',
      headerFormatter: filterHeaderFormatter,
      formatter: (value) => (value ? '✓' : ''),
      sort: true,
    },
    {
      dataField: 'phone_number',
      text: 'Phone Number',
      headerFormatter: filterHeaderFormatter,
      formatter: (value) => {
        return (
          <div style={{whiteSpace: 'nowrap'}}>
            {value}
          </div>
        );
      },
      filter: textFilter(),
      sort: true,
      headerStyle: {
        width: '110px'
      }
    },
    {
      dataField: 'license_number',
      text: 'License Number',
      headerFormatter: filterHeaderFormatter,
      formatter: truncateFormatter,
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'license_state',
      text: 'License State',
      headerFormatter: filterHeaderFormatter,
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'license_expiration',
      text: 'License Expiration',
      sort: true,
      headerFormatter: filterHeaderFormatter,
      formatter: dateFormatter
    },
    {
      dataField: 'date_of_birth',
      text: 'Date of Birth',
      sort: true,
      headerFormatter: filterHeaderFormatter,
      formatter: dateFormatter
    },
    {
      dataField: 'date_of_hire',
      text: 'Date of Hire',
      sort: true,
      headerFormatter: filterHeaderFormatter,
      formatter: dateFormatter

    },
    {
      dataField: 'physical_expiration',
      text: 'Physical Expiration',
      sort: true,
      headerFormatter: filterHeaderFormatter,
      formatter: dateFormatter
    },
    {
      dataField: 'email',
      text: 'Email',
      headerFormatter: filterHeaderFormatter,
      formatter: truncateFormatter,
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'address',
      text: 'Address',
      headerFormatter: filterHeaderFormatter,
      formatter: truncateFormatter,
      filter: textFilter(),
    },
    {
      dataField: 'city',
      text: 'City',
      headerFormatter: filterHeaderFormatter,
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'state',
      text: 'State',
      headerFormatter: filterHeaderFormatter,
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'zip',
      text: 'Zip',
      headerFormatter: filterHeaderFormatter,
      filter: textFilter(),
      sort: true,
    },
  ];

  useEffect(() => {
    handleSearch(false);
  }, [handleSearch]);

  return (
    <Row>
      <Col>
        <Row>
          <Col xs={12} sm className="mt-2">
            <DriverModal
              driverData={driverFormValues}
              showModal={showModal}
              onClose={() => setShowModal(false)}
              onOpen={() => setShowModal(true)}
            />
          </Col>
        </Row>

        {!!errorMessage &&
        <div style={{textAlign: 'center'}}>
          <hr/>
          <h4>Something went wrong.</h4>
          <br />
          <div>
            If you continue to see this message, please contact tech support.
          </div>
        </div>
        }

        {!errorMessage && drivers.length <= 0 &&
          <div style={{textAlign: 'center'}}>
            <hr/>
            <Spinner animation="border" variant="primary" />
          </div>
        }

        {drivers.length > 0 &&
          <ToolkitProvider
            keyField="id"
            data={drivers}
            columns={columns}
            exportCSV={{
              exportAll: false,
              onlyExportFiltered: true,
            }}
          >
            {
              (props) => (
                <div>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading,react/prop-types */}
                  <ExportCSVButton {...props.csvProps} className="exportButton">
                    Export
                    {' '}
                    <img height="18" alt="Excel Logo" src="./excel.svg"/>
                  </ExportCSVButton>
                  <hr/>
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="id"
                    data={drivers}
                    columns={columns}
                    onTableChange={onTableChange}
                    pagination={paginationFactory()}
                    noDataIndication={() => <div>No Results</div>}
                    filter={filterFactory()}
                    responsive
                    striped
                    hover
                    bootstrap4
                    condensed
                  />
                </div>
              )
            }
          </ToolkitProvider>
        }
      </Col>
    </Row>
  );
};

export default Drivers;
