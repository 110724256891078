import XLSX from 'xlsx';
import moment from 'moment';
import promiseForEach from '../utils/promiseForEach';
import getMove from '../utils/getMove';
import submitMove from '../utils/submitMove';

const ProgressPromise = require('progress-promise');

const toMap = {
  'GLOBAL 1': 'G1',
  'GLOBAL 2': 'G2',
  'GLOBAL 3': 'G3',
  'GLOBAL 4': 'G4',
  'CHICAGO 47TH ST TV': 'NS47',
  LANDERS: 'NS79',
  'YARD CENTER': 'MOPAC',
  'CHICAGO ENGLEW TV': 'NS63',
  CALUMET: 'NSCAL',
};

const ProcessSOO = (file, from) => {
  return new ProgressPromise((resolve, reject, progress) => {
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.onload = () => {
      const xlsData = new Uint8Array(reader.result);
      const workbook = XLSX.read(xlsData, { type: 'array' });

      let sheet = workbook.Sheets['First Sheet'];
      sheet = XLSX.utils.sheet_to_json(sheet, { raw: false });

      console.table(sheet);

      // eslint-disable-next-line no-shadow
      let moves = sheet.map((row) => {
        if (!toMap[row['Dest Final']]) {
          return null; // Move should not be in this grounding sheet.
        }

        return {
          trailer: row['Unit Id'],
          type: (row['Load/Empty']) ? row['Load/Empty'].toUpperCase() : row['L/E'].toUpperCase(),
          status: 'GROUNDED',
          to: toMap[row['Dest Final']] || row['Dest Final'],
          from,
          grounded_time: (row['Ground Time']) ?
            moment(row['Ground Time']).format('YYYY-MM-DDTHH:mm') : '',
          shipper: 'SOO'
        };
      });

      // Filters out null values from items with bad 'Dest Final' values
      moves = moves.filter((move) => move);

      console.table(moves);

      let updatedMoves = 0;
      let addedMoves = 0;
      let ignoredMoves = 0;

      let sixMonthsAgo = moment().subtract(6, 'months');

      promiseForEach(moves, (move) => {
        return getMove(move.trailer, move.to, move.from)
          .then((result) => {
            // Determine if this move is new or not
            let isNew = !result;

            // If it has a result (same trailer, to, and from), it is only going
            // to be new if the existing result is older than 6 months
            if (result && result.assigned_time) {
              if (moment(result.assigned_time).isSameOrBefore(sixMonthsAgo)) {
                isNew = true;
              }
            }

            if (!isNew) {
              result.grounded_time = move.grounded_time;

              let ignoreStatus = [
                'ASSIGNED',
                'DELIVERED',
                'CANCELED',
                'ACCESSORIAL'
              ];

              if (ignoreStatus.includes(result.status)) {
                ignoredMoves++;
                progress(`${move.trailer} was found, but was ignored.`);
                return;
              }

              result.status = 'GROUNDED';

              if (result.driver) {
                result.driver_id = result.driver.id;
              }

              progress(`${move.trailer} was found in the system. Updating.`);
              submitMove(result);
              updatedMoves++;
            } else {
              progress(`${move.trailer} was not found in the system. Adding.`);
              submitMove(move);
              addedMoves++;
            }
          });
      }).then(() => {
        progress(`Processed ${moves.length} moves.`);
        progress(`Updated ${updatedMoves} moves, added ${addedMoves} moves, and ignored ${ignoredMoves} moves.`);
      });
    };

    reader.readAsArrayBuffer(file);
  });
};

export default ProcessSOO;
