import { useEffect, useState } from 'react';
import moment from 'moment';

const formatter = (timestamp) => moment(timestamp).fromNow();

const LiveTimestamp = ({ timestamp, interval = 1000, prefix = null }) => {
  const [timestampString, setTimestampString] = useState('');

  useEffect(() => {
    const timer = setInterval(
      () => setTimestampString(formatter(timestamp)),
      interval,
    );
    setTimestampString(formatter(timestamp));
    return () => clearInterval(timer);
  }, [timestamp, interval]);

  return `${(prefix !== null ? `${prefix} ` : '')}${timestampString}`;
};

export default LiveTimestamp;
