import React from 'react';
import { useEffect } from 'react';
import apiRoot from '../constants/apiRoot';
var qs = require('qs');

const MicrosoftAuthResponse = ({location}) => {
    const parsedQuery = qs.parse(location.search.split('?')[1]);
    const code = parsedQuery.code;
    const session_state = parsedQuery.session_state;

    useEffect(() => {
        fetch(`${apiRoot}/outlook/getToken?` + new URLSearchParams({
            code,
            session_state
        }))
          .then((response) => response.json())
          .then((data) => {
            console.log(data.message);
            window.close();
          });
      }, []);


    return (
        <div>Authenticating...</div>
    );
};

export default MicrosoftAuthResponse;