import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { CheckCircle, XCircle } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import SamsaraTypeahead from './SamsaraTypeahead';
import apiRoot from '../constants/apiRoot';

const TerminalItem = ({ terminal, items, onDeleted }) => {
  const [selectedSamsara, setSelectedSamsara] = useState([]);
  const [modalShown, setModalShown] = useState(false);

  const onSamsaraSelect = (items) => {
    setSelectedSamsara(items);

    fetch(`${apiRoot}/terminal/${terminal.name}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        samsara_id: items[0] ? items[0].id : null,
      }),
    });
  };

  const handleDeleteClick = useCallback(() => {
    // Encoded twice otherwise names with forward slashes would break router
    fetch(`${apiRoot}/terminal/${encodeURIComponent(encodeURIComponent(terminal.name))}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.status === 204) {
        onDeleted?.(terminal.name);
      }
    });
  }, [terminal.name, onDeleted]);

  const handleModalClose = () => {
    setModalShown(false);
  };

  useEffect(() => {
    if (!terminal.samsara_id || items.length === 0) return;
    setSelectedSamsara([items.find((x) => x.id === terminal.samsara_id)]);
  }, [items, terminal.samsara_id]);

  return (
    <>
      <Card className="my-3">
        <Card.Header className={selectedSamsara[0] ? 'text-success' : 'text-danger'}>
          <div className="d-flex align-items-center h-100">
            {selectedSamsara[0] ? <CheckCircle /> : <XCircle />}
            <strong className="ml-2">{terminal.name}</strong>
            <Button variant="danger" className="ml-auto" onClick={() => setModalShown(true)}>
              Delete
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Form id={`terminals-form-${terminal.name}`}>
            <Form.Row>
              <SamsaraTypeahead
                id={`samsara-typeahead-${terminal.name}`}
                items={items}
                selectedSamsara={selectedSamsara}
                onChange={onSamsaraSelect}
              />
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>

      <Modal show={modalShown} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Terminal &quot;{terminal.name}&quot;</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete &quot;{terminal.name}&quot;?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShown(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDeleteClick}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

TerminalItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  terminal: PropTypes.shape({
    name: PropTypes.string,
    samsara_id: PropTypes.string
  }),
  onDeleted: PropTypes.func
};

export default TerminalItem;
