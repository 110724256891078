import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import apiRoot from './constants/apiRoot';

const DriverModal = ({
  driverData = {},
  showModal = false,
  onClose,
  onOpen,
}) => {
  const [show, setShow] = useState(false);
  const [samsaraLoading, setSamsaraloading] = useState(true);
  const [samsaraDrivers, setSamsaraDrivers] = useState([]);
  const [selectedSamsaraDriver, setSelectedSamsaraDriver] = useState([]);
  const [samsaraDriversValid, setSamsaraDriversValid] = useState();
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
  } = useForm();

  const fetchSamsaraDrivers = () => {
    setSamsaraloading(true);
    fetch(`${apiRoot}/search/samsara/drivers`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
      .then((data) => {
        if (data.status === 'ok') {
          setSamsaraDrivers(data.message);
        }
      })
      .finally(() => setSamsaraloading(false));
  };

  const onSamsaraInput = (items) => {
    setSelectedSamsaraDriver(items);
    setValue('samsara_id', (items[0] ? items[0].id : ''));
    if (items.length === 0) {
      setSamsaraDriversValid(false);
    } else {
      setSamsaraDriversValid(true);
    }
  };
  
  useEffect(() => {
    if (samsaraLoading || !driverData.samsara_id || samsaraDrivers.length === 0) return;
    setSelectedSamsaraDriver([samsaraDrivers.find((x) => x.id === driverData.samsara_id)]);
  }, [samsaraLoading, samsaraDrivers, driverData.samsara_id]);

  useEffect(() => {
    setShow(showModal);
    if (showModal) {
      fetchSamsaraDrivers();
    }
  }, [showModal]);

  useEffect(() => {
    reset(driverData);
    if (driverData.samsara_id) {
      setValue('samsara_id', driverData.samsara_id);
      setSamsaraDriversValid(true);
    } else {
      setSelectedSamsaraDriver([]);
      setSamsaraDriversValid();
    }
  }, [driverData, reset, setValue]);

  const onSubmit = (formData) => {
    let method = 'POST';
    let path = `${apiRoot}/driver`;
    if (formData.id) {
      path += `/${formData.id}`;
      method = 'PATCH';
    }
    fetch(path, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json())
      .then((data) => {
        if (data.status === 'ok') {
          onClose();
        }
      });
  };

  return (
    <>
      <Button
        onClick={() => {
          reset({});
          onOpen?.();
          setSamsaraDriversValid();
          setSelectedSamsaraDriver([]);
        }}
        variant="primary"
        className="float-right"
      >
        New Driver
      </Button>
      <Button
        variant="info"
        className="float-right mr-2"
        href="https://cloud.samsara.com/o/2232/fleet/drivers"
        target="_blank"
      >
        Edit Samsara Drivers
      </Button>
      <Modal size="lg" show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="new-driver-form" onSubmit={handleSubmit(onSubmit)}>
            {driverData.id && (
              <Form.Control
                type="hidden"
                name="id"
                ref={register}
              />
            )}
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="first_name"
                  placeholder="First name"
                  ref={register}
                  isInvalid={!!errors.first_name}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder="Last name"
                  ref={register}
                  isInvalid={!!errors.last_name}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Nick Name</Form.Label>
                <Form.Control
                  type="text"
                  name="nick_name"
                  placeholder="Nick name"
                  ref={register({ required: true })}
                  isInvalid={!!errors.nick_name}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Date of birth</Form.Label>
                <FormControl
                  name="date_of_birth"
                  type="date"
                  ref={register}
                  isInvalid={!!errors.date_of_birth}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Date of hire</Form.Label>
                <FormControl
                  name="date_of_hire"
                  type="date"
                  ref={register}
                  isInvalid={!!errors.date_of_hire}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Physical Expiration</Form.Label>
                <FormControl
                  name="physical_expiration"
                  type="date"
                  ref={register}
                  isInvalid={!!errors.physical_expiration}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>SSN</Form.Label>
                <Form.Control
                  name="ssn"
                  type="text"
                  placeholder="SSN"
                  ref={register}
                  isInvalid={!!errors.ssn}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>License Number</Form.Label>
                <FormControl
                  name="license_number"
                  type="text"
                  placeholder="License number"
                  ref={register}
                  isInvalid={!!errors.license_number}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>License State</Form.Label>
                <FormControl
                  name="license_state"
                  type="text"
                  placeholder="License state"
                  ref={register}
                  isInvalid={!!errors.license_state}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>License Expiration</Form.Label>
                <FormControl
                  name="license_expiration"
                  type="text"
                  placeholder="License expiration"
                  ref={register}
                  isInvalid={!!errors.license_expiration}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Phone Number</Form.Label>
                <FormControl
                  name="phone_number"
                  type="text"
                  placeholder="Phone number"
                  ref={register}
                  isInvalid={!!errors.phone_number}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  ref={register}
                  isInvalid={!!errors.email}
                />
              </Form.Group>
            </Form.Row>

            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                name="address"
                type="text"
                ref={register}
                isInvalid={!!errors.address}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control
                name="city"
                type="text"
                ref={register}
                isInvalid={!!errors.city}
              />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>State</Form.Label>
                <Form.Control
                  name="state"
                  type="text"
                  ref={register}
                  isInvalid={!!errors.state}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  name="zip"
                  type="number"
                  ref={register}
                  isInvalid={!!errors.zip}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label></Form.Label>
                <div>
                  <Form.Switch
                    name="active"
                    custom
                    inline
                    label="Active"
                    id="switch_active"
                    ref={register}
                  />
                  <Form.Switch
                    name="hazmat"
                    custom
                    inline
                    label="Hazmat"
                    id="switch_hazmat"
                    ref={register}
                  />
                  <Form.Switch
                    name="can_move_pups"
                    custom
                    inline
                    label="Can Move Pups"
                    id="switch_can_move_pups"
                    ref={register}
                  />
                </div>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Samsara</Form.Label>
                {samsaraLoading ? (
                  <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <Typeahead
                    id="samsara_typeahead"
                    labelKey="name"
                    flip
                    positionFixed
                    onChange={onSamsaraInput}
                    options={samsaraDrivers}
                    placeholder="Assign to Samsara Driver..."
                    selected={selectedSamsaraDriver}
                    isValid={samsaraDriversValid}
                  />
                )}
                <Form.Control
                  type="hidden"
                  name="samsara_id"
                  ref={register}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" form="new-driver-form">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

DriverModal.propTypes = {
  driverData: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  showModal: PropTypes.bool
};

export default DriverModal;
