import React, { useState, useEffect } from 'react';
import { CheckCircle, XCircle } from 'react-bootstrap-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';
import LiveTimestamp from '../components/LiveTimestamp';
import apiRoot from '../constants/apiRoot';

const DriversWorking = () => {
  const [drivers, setDrivers] = useState({});

  useEffect(() => {
    fetch(`${apiRoot}/search/drivers`)
      .then((response) => response.json())
      .then((data) => {
        const newDrivers = Object.assign({},
          ...data.message.map((driver) => ({
            [driver.id]: driver,
          })));
        setDrivers(newDrivers);
      });
  }, []);

  const handleWorkingToggle = (evt, id) => {
    const { checked } = evt.target;
    fetch(`${apiRoot}/driver/${id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ is_working: checked }),
    })
      .then((res) => res.json())
      .then((driver) => {
        setDrivers((oldDrivers) => {
          const currentDrivers = { ...oldDrivers };
          const newDriver = { [driver.message.id]: driver.message };
          return { ...currentDrivers, ...newDriver };
        });
      });
  };

  return (
    <div className="driversWorkingMain">
      <Row>
        <Col>
          <ListGroup className="my-3">
            {Object.keys(drivers).map((id) => (
              <ListGroup.Item
                key={id}
                className="d-flex justify-content-between"
              >
                <div>
                  <h6 className="d-inline">{drivers[id].nick_name}</h6>
                  <br />
                  <small className="text-muted">{`${drivers[id].first_name} ${drivers[id].last_name}`}</small>
                  <br />
                  <small>{drivers[id].phone_number}</small>
                  <div className="text-nowrap pr-2">
                    <span className={drivers[id].hazmat && 'text-success'}>
                      {drivers[id].hazmat ? <CheckCircle /> : <XCircle />}
                      <small className="ml-1">Hazmat</small>
                    </span>
                    <span className={`ml-2 ${drivers[id].can_move_pups && 'text-success'}`}>
                      {drivers[id].can_move_pups ? <CheckCircle /> : <XCircle />}
                      <small className="ml-1">Pups</small>
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column text-right">
                  <Form.Check
                    defaultChecked={drivers[id].is_working}
                    type="switch"
                    id={`working-switch-${id}`}
                    label="Working"
                    onChange={(evt) => handleWorkingToggle(evt, id)}
                  />
                  {
                    drivers[id].is_working && drivers[id].latest_worktime
                    && (
                    <OverlayTrigger
                      placement="top"
                      overlay={(<Tooltip>{moment(drivers[id].latest_worktime.time_start).format('HH:mm:ss DD/MM/YYYY')}</Tooltip>)}
                    >
                      <small className="text-muted mt-auto text-right">
                        {
                          moment(drivers[id].latest_worktime.time_start).isAfter(moment().subtract(23, 'hour'))
                            ? (
                              <LiveTimestamp
                                timestamp={drivers[id].latest_worktime.time_start}
                                interval={1000}
                                prefix="Started"
                              />
                            )
                            : moment(drivers[id].latest_worktime.time_start).format('HH:mm:ss DD/MM/YYYY')
                        }
                      </small>
                    </OverlayTrigger>
                    )
                  }
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};

export default DriversWorking;
