import apiRoot from '../constants/apiRoot';

const submitMove = (move) => {
  const defaultMove = {
    type: 'LOAD',
    status: 'RECEIVED',
    destination: '',
    grounded_time: '',
    assigned_time: '',
    priority: '',
    driver_id: '',
    temp: '',
    trailer_size: '',
    is_ups: false,
    is_haz: false,
    is_bad: false,
    description: '',
    batch_number: '',
  };

  move = Object.assign({}, defaultMove, move);

  let url = `${apiRoot}/move`;
  let method = 'POST';

  if (move.id) {
    url += `/${move.id}`;
    method = 'PATCH';
  }

  return new Promise((resolve) => {
    fetch(url, {
      headers: {
        accept: '*/*',
        'accept-language': 'en-US,en;q=0.9',
        'content-type': 'application/json',
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'cors',
        'sec-fetch-site': 'cross-site',
        'x-centera-extension-version': '1.5',
      },
      referrer: document.location.host,
      referrerPolicy: 'no-referrer-when-downgrade',
      body: JSON.stringify(move),
      method: method,
      mode: 'cors',
      credentials: 'omit',
    })
      .then(() => {
        resolve();
      });
  });
};

export default submitMove;
