import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SamsaraTypeahead from './SamsaraTypeahead';
import apiRoot from '../constants/apiRoot';

const AddTerminalModal = ({ modalShown, samsaraItems, onHide, onSubmitted }) => {
  const [selectedSamsara, setSelectedSamsara] = useState([]);
  const [name, setName] = useState('');

  const onSamsaraSelect = (items) => {
    setSelectedSamsara(items);
  };

  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
    fetch(`${apiRoot}/terminal`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        samsara_id: selectedSamsara[0] ? selectedSamsara[0].id : null,
      }),
    }).then((response) => response.json())
    .then(() => {
      onSubmitted?.();
      setName('');
      setSelectedSamsara([]);
    });
  }, [onSubmitted, selectedSamsara, name]);

  return (
    <Modal show={modalShown}>
      <Modal.Header closeButton>
        <Modal.Title>New Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="terminal-form-add" onSubmit={onSubmit}>
          <Form.Row>
            <Form.Group>
              <Form.Label>Location Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(evt) => setName(evt.target.value)}
              />
            </Form.Group>
            <SamsaraTypeahead
              id="samsara-terminal-add"
              items={samsaraItems}
              selectedSamsara={selectedSamsara}
              onChange={onSamsaraSelect}
            />
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
        <Button variant="primary" type="submit" form="terminal-form-add">Add</Button>
      </Modal.Footer>
    </Modal>
  );
};

AddTerminalModal.propTypes = {
  modalShown: PropTypes.any,
  onHide: PropTypes.any,
  onSubmitted: PropTypes.func,
  samsaraItems: PropTypes.any
};

export default AddTerminalModal;
