const promiseForEach = (arr, cb) => {
  return new Promise((resolve, reject) => {
    let i = 0;

    // eslint-disable-next-line no-var
    const nextPromise = function () {
      if (i >= arr.length) {
        resolve();
        return;
      }

      // Process next function. Wrap in `Promise.resolve` in case
      // the function does not return a promise
      const newPromise = Promise.resolve(cb(arr[i], i));

      // eslint-disable-next-line no-plusplus
      i++;

      // eslint-disable-next-line consistent-return
      return newPromise.then(nextPromise);
    };

    // Kick off the chain.
    return Promise.resolve()
      .then(nextPromise);
  });
};

export default promiseForEach;
