import React, {
  useEffect,
  useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';

const ProgressModal = ({ progress }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    // I'm so sorry for this hack @todo Please fix me
    const modal = document.querySelectorAll('.modal-body');
    if (modal[0]) {
      modal[0].scrollTop = 10000000;
    }
  });

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Processing File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {progress.map((message, i) => {
            return (
              <div key={i}>{message}</div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProgressModal;
