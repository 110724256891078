import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';

const SamsaraTypeahead = ({ id, items, selectedSamsara, onChange }) => (
  <Form.Group as={Col}>
    <Form.Label>Samsara Address</Form.Label>
    {(items.length === 0) ? (
      <div className="text-center">
        <Spinner animation="border" variant="primary" />
      </div>
    ) : (
      <Typeahead
        id={id}
        labelKey="name"
        clearButton
        flip
        positionFixed
        onChange={onChange}
        options={items}
        placeholder="Assign to Samsara address..."
        selected={selectedSamsara}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
          <>
            <Form.Control
              {...inputProps}
              ref={(input) => {
                inputRef(input);
                referenceElementRef(input);
              }}
            />
            {selectedSamsara[0] && (
              <Form.Text className="text-muted position-absolute">{selectedSamsara[0].address}</Form.Text>
            )}
          </>
        )}
        renderMenuItemChildren={(option, { text }) => (
          <>
            <Highlighter search={text}>
              {option.name}
            </Highlighter>
            <small className="d-block text-muted text-wrap">{option.address}</small>
          </>
        )}
      />
    )}
  </Form.Group>
);

SamsaraTypeahead.propTypes = {
  id: PropTypes.string.isRequired,
  selectedSamsara: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  items: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
};

export default SamsaraTypeahead;
