import React from 'react';
import { useState, useEffect } from 'react';
import apiRoot from '../constants/apiRoot';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Logs = () => {
    const [logs, setLogs] = useState([]);
    const [log, setLog] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        fetch(`${apiRoot}/logs`)
          .then((response) => response.json())
          .then((data) => {
            setLogs(data.message);
          });
      }, []);

    const getLog = (id) => {
    fetch(`${apiRoot}/logs/${id}`)
        .then((response) => response.json())
        .then((data) => {
        setLog(data.message);
        setShow(true);
        });
    };

    return (
        <div>
            {logs.map((log) => {
                console.log(log);
                return (
                    <div 
                        key={log.id} 
                        style={{color: (log.exit_code ? 'red' : 'green')}}
                        onClick={() => getLog(log.id)}
                    >
                        <span>{log.timestamp}</span>
                        -
                        <span>{log.script_name}</span>
                    </div>
                );
            })}

            <Modal size="xl" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>{log.script_name} - {log.timestamp}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><pre>{log.output}</pre></Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
        </div>

        
    );
};

export default Logs;