import apiRoot from '../constants/apiRoot';

const getMove = (trailer, to, from, shiftTimeFrom, shiftTimeTo) => {
  return new Promise((resolve, reject) => {
    const url = `${apiRoot}/search/moves?term=${trailer}&to=${to}&from=${from}`;

    fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 'ok') {
          resolve(result.message[0] || false);
        } else if (result.status === 'error') {
          reject();
        }
      }, (error) => {
        reject();
      });
  });
};

export default getMove;
