import React, {useState} from 'react';
import apiRoot from '../constants/apiRoot';
import Button from 'react-bootstrap/Button';

const MicrosoftAuth = () => {
    const [authenticated, setAuthenticated] = useState(false);
    let authWindow, intervalId;

    const openAuthWindow = () => {
        fetch(`${apiRoot}/outlook/auth`)
          .then((response) => response.json())
          .then((data) => {
            authWindow = window.open(data.message);

            intervalId = setInterval(() => {
                if (authWindow.closed) {
                    clearInterval(intervalId);
                    setAuthenticated(true);
                }
            }, 1000);
          });
    };

    return (
        <div>
            {!authenticated &&
                <Button onClick={openAuthWindow}>Authenticate</Button>
            }
            {authenticated &&
                <Button disabled>Authentication Complete</Button>
            }
        </div>
    );
};

export default MicrosoftAuth;