import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Search from './routes/Search';
import Drivers from './routes/Drivers';
import DriversWorking from './routes/DriversWorking';
import Terminals from './routes/Terminals';
import Logs from './routes/Logs';
import MicrosoftAuthResponse from './routes/MicrosoftAuthResponse';
import MicrosoftAuth from './routes/MicrosoftAuth';

const App = () => (
  <Router>
    <Container fluid>
      <Switch>
        <Route exact path="/" component={Search} />
        <Route path="/search" component={Search} />
        <Route path="/drivers" component={Drivers} />
        <Route path="/terminals" component={Terminals} />
        <Route path="/driversworking" component={DriversWorking} />
        <Route path="/logs" component={Logs} />
        <Route path="/microsoft-auth" component={MicrosoftAuth} />
        <Route path="/microsoft-auth-response" component={MicrosoftAuthResponse} />
      </Switch>
    </Container>
  </Router>
);

export default App;
