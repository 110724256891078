import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import TerminalItem from './TerminalItem';
import AddTerminalModal from './AddTerminalModal';
import apiRoot from '../constants/apiRoot';

let abortController = null;
let isSearching = false;

const Terminals = () => {
  const [terminals, setTerminals] = useState([]);
  const [samsara, setSamsara] = useState([]);
  const [modalShown, setModalShown] = useState(false);

  const searchInput = useRef('');
  const search = useDebounceCallback(() => {
    abortController = new AbortController();
    isSearching = true;

    fetch(`${apiRoot}/search/terminals`, {
      signal: abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => {
        isSearching = false;
        setTerminals(data.message);
      })
      .catch((err) => {
        // rethrow everything but AbortError
        if (err.name !== 'AbortError') {
          isSearching = false;
          throw err;
        }
      });
  }, 300);

  const handleSearch = useCallback(() => {
    if (isSearching) {
      abortController.abort();
    }
    search(searchInput.current.value);
  }, [search]);

  const handleDelete = useCallback((terminalId) => {
    setTerminals((t) => {
      return t.filter((x) => x.name !== terminalId);
    });
  }, []);

  const handleSubmitted = useCallback(() => {
    handleSearch();
    setModalShown(false);
  }, [handleSearch]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  useEffect(() => {
    fetch(`${apiRoot}/search/samsara/addresses`)
      .then((response) => response.json())
      .then((data) => {
        setSamsara(data.message);
      });
  }, []);

  return (
    <Row>
      <Col>
        <Row>
          <Col xs={12} sm className="mt-2">
            <Button
              onClick={() => {
                setModalShown(true);
              }}
              variant="primary"
              className="float-right"
            >
              New Location
            </Button>
            <Button
              variant="info"
              className="float-right mr-2"
              href="https://cloud.samsara.com/o/2232/fleet/config/addresses"
              target="_blank"
            >
              Edit Samsara Locations
            </Button>
          </Col>
        </Row>
        {terminals.map((terminal) => (
          <TerminalItem
            key={terminal.name}
            terminal={terminal}
            items={samsara}
            onDeleted={handleDelete}
          />
        ))}
      </Col>

      <AddTerminalModal
        modalShown={modalShown}
        samsaraItems={samsara}
        onHide={() => setModalShown(false)}
        onSubmitted={handleSubmitted}
      />
    </Row>
  );
};

export default Terminals;
